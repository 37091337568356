export default function authentication({store, redirect, route}){
    if(!store.state.auth.loggedIn){
        return redirect("/login");
    }
    else{
        if(route.name === "login"){
            return redirect("/");
        }
        else if(["tracking","brands"].includes(route.name) && !['streamoidTeamLead','streamoidTagger','trackalogAdmin'].includes(store.state.auth.user.role)){
            return redirect("/");
        }
       return ;
    }
}