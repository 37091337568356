import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _fd83926c = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _b4d4bac6 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _095d8299 = () => interopDefault(import('../pages/newbatch/index.vue' /* webpackChunkName: "pages/newbatch/index" */))
const _17f1499a = () => interopDefault(import('../pages/tracking.vue' /* webpackChunkName: "pages/tracking" */))
const _ef4c409e = () => interopDefault(import('../pages/newbatch/csvMapping.vue' /* webpackChunkName: "pages/newbatch/csvMapping" */))
const _f13438be = () => interopDefault(import('../pages/newbatch/review.vue' /* webpackChunkName: "pages/newbatch/review" */))
const _1cd3335c = () => interopDefault(import('../pages/newbatch/uploadStatus.vue' /* webpackChunkName: "pages/newbatch/uploadStatus" */))
const _6747ee78 = () => interopDefault(import('../pages/batch/images/_name.vue' /* webpackChunkName: "pages/batch/images/_name" */))
const _52c9b765 = () => interopDefault(import('../pages/batch/tags/_name.vue' /* webpackChunkName: "pages/batch/tags/_name" */))
const _1d0d4c96 = () => interopDefault(import('../pages/brand/_name.vue' /* webpackChunkName: "pages/brand/_name" */))
const _6fe6fa4e = () => interopDefault(import('../pages/product/_id.vue' /* webpackChunkName: "pages/product/_id" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/faq",
    component: _fd83926c,
    name: "faq"
  }, {
    path: "/login",
    component: _b4d4bac6,
    name: "login"
  }, {
    path: "/newbatch",
    component: _095d8299,
    name: "newbatch"
  }, {
    path: "/tracking",
    component: _17f1499a,
    name: "tracking"
  }, {
    path: "/newbatch/csvMapping",
    component: _ef4c409e,
    name: "newbatch-csvMapping"
  }, {
    path: "/newbatch/review",
    component: _f13438be,
    name: "newbatch-review"
  }, {
    path: "/newbatch/uploadStatus",
    component: _1cd3335c,
    name: "newbatch-uploadStatus"
  }, {
    path: "/batch/images/:name?",
    component: _6747ee78,
    name: "batch-images-name"
  }, {
    path: "/batch/tags/:name?",
    component: _52c9b765,
    name: "batch-tags-name"
  }, {
    path: "/brand/:name?",
    component: _1d0d4c96,
    name: "brand-name"
  }, {
    path: "/product/:id?",
    component: _6fe6fa4e,
    name: "product-id"
  }, {
    path: "/",
    component: _482400f4,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
