import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
   customboundedimage: () => import('../../components/ customboundedimage.vue' /* webpackChunkName: "components/ customboundedimage" */).then(c => wrapFunctional(c.default || c)),
  Batches: () => import('../../components/batches.vue' /* webpackChunkName: "components/batches" */).then(c => wrapFunctional(c.default || c)),
  BottomNav: () => import('../../components/bottomNav.vue' /* webpackChunkName: "components/bottom-nav" */).then(c => wrapFunctional(c.default || c)),
  Facets: () => import('../../components/facets.vue' /* webpackChunkName: "components/facets" */).then(c => wrapFunctional(c.default || c)),
  Faq: () => import('../../components/faq.vue' /* webpackChunkName: "components/faq" */).then(c => wrapFunctional(c.default || c)),
  ImageEditor: () => import('../../components/imageEditor.vue' /* webpackChunkName: "components/image-editor" */).then(c => wrapFunctional(c.default || c)),
  ImageReplacer: () => import('../../components/imageReplacer.vue' /* webpackChunkName: "components/image-replacer" */).then(c => wrapFunctional(c.default || c)),
  NavHeader: () => import('../../components/navHeader.vue' /* webpackChunkName: "components/nav-header" */).then(c => wrapFunctional(c.default || c)),
  NewBatch: () => import('../../components/newBatch.vue' /* webpackChunkName: "components/new-batch" */).then(c => wrapFunctional(c.default || c)),
  Pdp: () => import('../../components/pdp.vue' /* webpackChunkName: "components/pdp" */).then(c => wrapFunctional(c.default || c)),
  ProductsListGridView: () => import('../../components/productsListGridView.vue' /* webpackChunkName: "components/products-list-grid-view" */).then(c => wrapFunctional(c.default || c)),
  ProductsListImageQC: () => import('../../components/productsListImageQC.vue' /* webpackChunkName: "components/products-list-image-q-c" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
